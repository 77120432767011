@import 'fonts';

/* COLORS */
$color__black--text: #212529;
$color__gray--bg: #f5f5f5;
$color__gray--border: #e0e0e0;
$color__gray--border-light: #e0e0e0;
$color__gray--text: #9e9e9e;
$color__green: #74b841;
$color__orange: #f39100;
$color__orange--alert: #f8522a;
$color__orange--hover: #fca235;
$color__white: #ffffff;

/* FONT STYLES */
@mixin style__headline() {
  @include font__bold;
  font-size: 26px;
  line-height: 34px;
  padding: 0;
  margin: 0;
}

@mixin style__headline--modal() {
  @include font__bold;
  font-size: 22px;
  line-height: 32px;
  padding: 0;
  margin: 0;
}

@mixin style__text() {
  @include font__normal;
  font-size: 15px;
  line-height: 22px;
  padding: 0;
  margin: 0;
}

@mixin style__text--input() {
  @include font__normal;
  font-size: 22px;
  line-height: 22px;
  padding: 0;
  margin: 0;
}

@mixin style__text--label() {
  @include font__normal;
  font-size: 13px;
  line-height: 16px;
  padding: 0;
  margin: 0;
}

/* UTILITIES */
@mixin usable() {
  -webkit-user-select: auto;
  -webkit-touch-callout: default;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

/* RESPONSIVE */
@mixin desktop() {
  @media (min-width: 480px) {
    @content;
  }
}
