/* ubuntu-regular - latin */
@mixin font__normal() {
  font-family: Ubuntu, sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ubuntu-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../fonts/ubuntu-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ubuntu-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ubuntu-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ubuntu-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ubuntu-v15-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

/* ubuntu-700 - latin */
@mixin font__bold() {
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/ubuntu-v15-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../fonts/ubuntu-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ubuntu-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ubuntu-v15-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ubuntu-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ubuntu-v15-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
