@import '../../styles/_shared';

.RedeemOverlay {
  &__Loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba($color__white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__Exception {
    padding: 40px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__ExceptionText {
    @include style__text;
    margin: 30px 10%;
  }

  &__Details {
    padding: 10px 25px 40px;
  }

  &__Text {
    @include style__text;
    @include font__bold;
    margin: 0;
  }

  &__Message {
    margin: 30px 0;
  }
}
