@import '../../styles/_shared';

.InstallOverlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &--In {
    opacity: 1;
    visibility: visible;
  }

  &--Out {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.2s;
  }

  &__Text {
    @include style__text;
    color: $color__white;
    text-align: center;
    padding: 0 50px 100px;
    transition: transform 0.3s ease-in-out;

    &--In {
      transform: translateY(0);
      transition-delay: 0.2s;
    }

    &--Out {
      transform: translateY(100%);
    }
  }
}
