@import '../../styles/_shared';

// Default styles for random wysiwyg content
.WysiwygStyling {
  & > *:first-child {
    margin-top: 0 !important;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }

  p {
    @include style__text;
    line-height: 26px;
    padding: 0;
    margin: 20px 0;
  }

  a {
    color: $color__orange;
    text-decoration: underline;
    transition: colors 0.1s ease-in-out;

    &:hover {
      color: $color__orange--hover;
    }
  }

  ul,
  ol {
    @include style__text;
    line-height: 26px;
    margin: 20px 0;
    padding: 0;
  }

  ol {
    li {
      margin: 15px 0 15px 20px;
      padding-left: 5px;
    }
  }

  ul {
    list-style: none;

    li {
      background-image: url('./../../images/listitem.svg');
      background-position: left top 0.3rem;
      background-size: 9px;
      background-repeat: no-repeat;
      display: block;
      margin: 15px 0;
      padding-left: 25px;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
