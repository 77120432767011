// Contains common styles for inputs
@import '../../../styles/_shared';

@mixin Input__Wrapper() {
  position: relative;
  width: 100%;
  padding: 15px;
  border: 1px $color__gray--border-light solid;
  border-radius: 8px;
  margin-top: 25px;
}

@mixin Input__Validation($visible: false) {
  @include style__text--label;
  color: $color__orange;
  position: absolute;
  left: 12px;
  top: -8px;
  background: $color__white;
  padding: 0 3px;
  display: none;

  @if $visible {
    display: block;
  }
}

@mixin Input__Textbox($readonly: false) {
  @include style__text--input;
  @include usable;
  color: $color__black--text;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $color__gray--text;
    background: none;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba($color__gray--text, 0.3);
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: rgba($color__gray--text, 0.3);
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: rgba($color__gray--text, 0.3);
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: rgba($color__gray--text, 0.3);
  }
}
