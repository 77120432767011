@import './inputs';

.Textbox {
  @include Input__Wrapper();

  &__Input {
    @include Input__Textbox();
  }

  &--Readonly &__Input {
    @include Input__Textbox(true);
  }

  &__Validation {
    @include Input__Validation();

    &--Visible {
      @include Input__Validation(true);
    }
  }
}
