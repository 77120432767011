@import '../../styles/_shared';

.HelpOverlay {
  &__Steps {
    padding: 5px 25px;
  }

  &__StepText {
    @include style__text;
    margin: 0;
  }

  &__DismissButtonWrapper {
    padding: 0 25px;
  }

  &__QrLinkWrapper {
    @include style__text;
    padding: 20px;
    text-align: center;
  }

  &__QrLink {
    color: $color__orange;
    text-decoration: none;
  }

  &__Contact {
    border-top: 1px $color__gray--border solid;
    padding: 35px 25px;
  }

  &__ContactTitle {
    @include style__headline--modal;
  }

  &__ContactText {
    @include style__text;
    margin: 15px 0 0;
  }

  &__InstallWrapper {
    border-top: 1px $color__gray--border solid;
    padding: 25px 25px 35px;
    display: flex;
    align-items: center;
    justify-content: stretch;
  }

  &__InstallText {
    @include style__text;
    flex: 1 1 auto;
    margin: 0;
    padding-right: 15px;
  }

  &__Version {
    @include style__text;
    border-top: 1px $color__gray--border solid;
    padding: 25px;
    color: $color__gray--border;
  }
}
