.NavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Logo {
    height: 40px;
    width: auto;
  }
}
