@import '../../styles/_shared';

.QrScanner {
  text-align: center;
  padding: 0;

  @include desktop() {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 50px;
  }

  &__Title {
    @include style__headline;
    margin-bottom: 30px;

    @include desktop() {
      flex: none;
      width: 100%;
      margin-bottom: 60px;
    }
  }

  &__Scan,
  &__Manual {
    @include desktop() {
      width: 50%;
      padding: 0 20px;
    }
  }

  &__Reader {
    position: relative;
    margin: 0 auto;
    width: 80%;
    padding-bottom: 80%;
  }

  &__Dummy,
  &__ViewFinder {
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
  }

  &__Dummy {
    z-index: 3;
    background: $color__white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &--Visible {
      opacity: 1;
    }
  }

  &__ViewFinder {
    z-index: 2;
  }

  &__Frame {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &__SwitchCamera {
    margin: 20px 0 30px;
  }

  &__Text {
    @include style__text;
    margin: 30px 0 15px;
    text-align: center;
  }

  &__Input {
    position: relative;
  }

  &__Textbox {
    letter-spacing: 0.3rem;
    padding-right: 40px !important;
  }

  &__ManualButton {
    position: absolute;
    right: 13px;
    top: 13px;
  }
}
