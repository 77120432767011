@import '../styles/_shared';

.App {
  padding: 25px;

  &__Main {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__Meta {
    padding: 25px 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      position: absolute;
      right: 0;
      bottom: 30px;
      justify-content: flex-end;
    }
  }

  &__MetaLink {
    color: $color__gray--text;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
  }
}
