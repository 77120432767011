@import '../../styles/_shared';

.Overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  @include desktop() {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--In {
    opacity: 1;
    visibility: visible;
  }

  &--Out {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.2s;
  }

  &__Wrapper {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: calc(100% - 50px);
    background: $color__white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    @include desktop() {
      position: static;
      width: 500px;
      height: auto;
    }

    &--In {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition-delay: 0.2s;
    }

    &--Out {
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
    }
  }

  &__Close {
    position: absolute;
    right: 25px;
    top: 28px;
    font-size: 0;
  }

  &__Title {
    @include style__headline--modal;
    padding: 25px;
    border-bottom: 1px $color__gray--border solid;
    text-align: left;
    flex: none;
  }

  &__Content {
    flex: 1 1 auto;
    overflow-y: scroll;
    min-height: 200px;
  }
}
