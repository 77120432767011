@import 'styles/_shared';

html,
body {
  @include font__normal;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  image-rendering: auto;
}

body.has-overlay {
  // This class is set by react-helmet when an overlay is shown
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

b,
strong {
  @include font__bold;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}
