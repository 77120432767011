@import '../../styles/_shared';

.Message {
  display: flex;
  padding: 10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 7px;
  align-items: center;

  &--SUCCESS {
    color: $color__green;
    border-color: $color__green;
  }

  &--ALERT {
    color: $color__orange--alert;
    border-color: $color__orange--alert;
  }

  &__Text {
    @include style__text;
    padding-left: 10px;
    margin: 0;
    text-align: left;
  }
}
