.HelpStep {
  margin: 25px 0;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;

  &__IconWrapper {
    width: 58px;
    margin-right: 20px;
    flex: none;
    padding-top: 5px;
  }

  &__Icon {
    width: 100%;
    height: auto;
  }

  &__Content {
    flex: 1 1 auto;
  }
}
