@import '../../styles/_shared';

.RedeemItem {
  display: block;
  margin: 20px 0;
  text-align: left;

  &__Title {
    @include style__text;
    color: $color__gray--text;
    margin-bottom: 5px;
  }
}
